import { render, staticRenderFns } from "./estabelecer.vue?vue&type=template&id=cbf521e6&scoped=true"
import script from "./estabelecer.vue?vue&type=script&lang=js"
export * from "./estabelecer.vue?vue&type=script&lang=js"
import style0 from "./estabelecer.vue?vue&type=style&index=0&id=cbf521e6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cbf521e6",
  null
  
)

export default component.exports