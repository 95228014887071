<template>
  <div class="container">
    <div class="row" v-if="show_table">
      <div class="col-lg-12 col-md-12 col-sm-12">
        <b-table
          head-variant="dark"
          striped
          hover
          :items="vet_pedidos"
          responsive
          id="doc-itens"
          :fields="fields_itens_corrente"
        >
          <template #cell(check)="row">
            <!-- <span>{{row.item.vecimento}}</span> -->
            <b-form-checkbox
              value="3"
              v-model="row.item.check"
              @change="seleciona_pedido_saldo(row.item, $event, row.index)"
              :disabled="row.item.check == 5 ? true : false"
            >
            </b-form-checkbox>
          </template>
          <template #cell(nome)="row">
            <!-- <span>{{row.item.vecimento}}</span> -->
            <span class="badge badge-warning">{{
              row.item.fornecedor.pes_apelido
            }}</span>
          </template>
        </b-table>
      </div>
    </div>
    <div class="row d-flex justify-content-center align-items-center mt-4">
      <div class="col-md-6 text-center">
        <span
          >Saldo a Ser Vinculado :
          <span class="badge badge-success">{{ saldo_vincula }}</span></span
        >
      </div>
      <div class="col-md-6 text-center">
        <span
          >Total Selecionado :
          <span class="badge badge-info">{{ soma_selected }}</span></span
        >
      </div>
    </div>
    <div class="row d-flex justify-content-center align-items-center mt-4">
      <div class="col-lg-12 col-md-12 text-center">
        <div class="card mt-3">
          <div class="card-body mt-3">
            <span
              >Total Final :
              <span class="badge badge-danger">{{
                total_valor_vinculado
              }}</span></span
            >
          </div>
        </div>
      </div>
    </div>
    <div class="row d-flex justify-content-center align-items-center mt-4">
      <div class="col-md-12 text-center">
        <button class="btn btn-light" @click="envia_saldo()">Vincular</button>
      </div>
    </div>
  </div>
</template>

<script>
//import { http } from "../../../../helpers/easyindustriaapi/config";
//import {axios}  from "axios";
export default {
  props: {
    array_pedidos: { type: Array },
    vet_adiantamento: { type: Array },
    Objt_pre_pedido: { type: Object },
    saldo: { type: Number },
  },
  data() {
    return {
      array_adiantamentos: [],
      objeto_saldo_pre_pedido: {},
      show_table: true,
      saldo_vincula2: null,
      total_valor_vinculado: null,
      resp_vinculo: null,
      vet_soma: [],
      soma_selected: 0,
      saldo_vincula: null,
      pre_pedido_obj: null,
      fields_itens_corrente: [
        {
          label: "",
          key: "check",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Nome",
          key: "nome",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Data",
          key: "data",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Valor",
          key: "valor",
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          label: "Saldo",
          key: "ccorrente_limite",
          tdClass: "text-center",
          thClass: "text-center",
        },

        // {
        //   label: "Ações",
        //   key: "excluir",
        //   tdClass: "text-center",
        //   thClass: "text-center",
        // },
        // { label: "Ações", key: "excluir2", tdClass: 'text-center', thClass: 'text-center' },
      ],
      nome: null,
      vet_pedidos: [],
    };
  },
  created() {
    this.vet_pedidos = this.array_pedidos;
    this.pre_pedido_obj = this.Objt_pre_pedido;
    this.saldo_vincula = this.saldo;
    this.array_adiantamentos = this.vet_adiantamento;
  },
  mounted() {
    // console.log("Montrando vetor de pedios Geral");
    // console.log(this.vet_pedidos);
    // console.log("Mostrnado Objeto Pre_pedidos");
    // console.log(this.pre_pedido_obj);
    // console.log("Mostrando Saldo a ser Vinculado:");
    // console.log(this.saldo_vincula);
    console.log("Monstrando Vet adiantamentos no saldo");
    console.log(this.array_adiantamentos);
  },
  methods: {
    // criado para asssocias o pre_pedio a o objeto saldo_pre
    // pois o segundo objeto , tem que ter todos os atriutos do pre_peidos , mais o valor do sald
    // e data
    associa_objeto_saldo() {},
    envia_saldo() {
      let objeto_adant = this.array_adiantamentos[0];
      objeto_adant.valor_pag = this.saldo_vincula;
     
      // if (this.saldo_vincula == 0) {
      //   this.makeToast("danger", "Não é possivel vincular Saldo zerado");
      // } else {
      this.objeto_saldo_pre_pedido.valor_saldo = this.new_convert_srting_float(
       this.soma_selected - this.total_valor_vinculado
      );
      console.log("Mostrando os Obejtos")
      console.log(objeto_adant);
      console.log(objeto_adant);
      this.$emit("envia_saldo", this.objeto_saldo_pre_pedido, objeto_adant);
    },
    makeToast(variant = null, message = "Error generic") {
      this.counter++;
      this.$bvToast.toast(message, {
        title: `Notificação`,
        toaster: "b-toaster-bottom-right",
        variant: variant,
        solid: true,
        appendToast: true,
      });
    },
    // async vincular_saldo_final() {
    //   // id salod pq quando eu vincular ao pre_peido , ja vair ter um id;
    //   // this.objeto_saldo_pre_pedido.id_saldo = this.pre_pedido_obj.id;
    //   // console.log("mostrando o objeto");
    //   //  console.log(this.objeto_saldo_pre_pedido);
    //   //  let objeto;
    //   // criar duas colunas no para tabela prepediods , uam paar o valor do saldo , e ourt ara data
    //   console.log("");
    //   let objeto;
    //   try {
    //     let response = await http.put(
    //       "/pre-pedido/" + objeto.id + "?empresa_id=" + this.currentEmpresa.id,
    //       this.objeto
    //     );
    //     if (response) {
    //       //this.offLoader();
    //       if (response.status === 200) {
    //         this.makeToast("success", "Registro alterado");
    //         // this.getData();
    //         // this.editando = false;
    //       }
    //     }
    //   } catch (error) {
    //     //this.offLoader();
    //     if (error.response) {
    //       if (error.response.status === 404) {
    //         this.makeToast("danger", "Destino URL não encontrado!");
    //       } else {
    //         this.makeToast("danger", error);
    //       }
    //     }
    //   }
    // },
    soma_vet_selecionados(event) {
      console.log(event);
      let soma = 0;
      this.vet_soma.forEach((e) => {
        soma = soma + e.valor;
      });

      this.soma_selected = soma;

      if (this.soma_selected > 0 && this.saldo_vincula > 0) {
        if (this.saldo_vincula > 0) {
          this.saldo_vincula2 = this.saldo_vincula;
        }
        if (this.soma_selected >= this.saldo_vincula) {
          console.log("entrei no if 2");
          this.total_valor_vinculado = this.soma_selected - this.saldo_vincula;
          let saldo_vincula = this.saldo_vincula;
          this.saldo_vincula =
            this.total_valor_vinculado - this.soma_selected + saldo_vincula;
          console.log("Monstrando total valor vinculado");
          console.log(this.total_valor_vinculado);
        } else if (this.soma_selected < this.saldo_vincula) {
          this.total_valor_vinculado = 0;
          let sobra = this.saldo_vincula - this.soma_selected;
          console.log("motrando sobra");
          console.log(this.saldo_vincula);
          this.saldo_vincula = sobra;
        }
      } else if (this.soma_selected >= 0 && this.saldo_vincula <= 0) {
        console.log("esntrei no esle if 3");

        if (this.soma_selected == 0) {
          this.saldo_vincula = this.saldo_vincula2;
          this.total_valor_vinculado = soma;
        }
      }
      // this.total_valor_vinculado =
      //   this.soma_selected > 0
      //     ? this.soma_selected - this.saldo_vincula
      //     : this.soma_selected;
    },

    seleciona_pedido_saldo(objeto_pedido, event, index) {
      
      this.objeto_saldo_pre_pedido = objeto_pedido;

      let valor = this.new_convert_srting_float(objeto_pedido.valor);
      let soma = this.new_convert_srting_float(this.soma_selected);
      
      if (event == 3) {
        let objeto = {
          id: objeto_pedido.id,
          valor: valor,
        };
        this.vet_soma.push({ ...objeto });
 
        this.soma_vet_selecionados(event);
      } else {
       
        this.vet_soma = this.vet_soma.filter(
          (item) => item.id !== objeto_pedido.id
        );
       
        this.soma_vet_selecionados(event);
      }
      if (this.saldo_vincula == 0) {
        this.makeToast(
          "danger",
          "Saldo a ser Vinculado está Zeardo, Não é Possivel selecionar outro Pedido "
        );
      }
      console.log("mostrando vetor soma no sadlo vinucla" + index);
      console.log(this.vet_soma);
      if (this.vet_soma.length > 0 && this.saldo_vincula == 0 ) {
        let vet_clone2 = [...this.vet_pedidos];
        vet_clone2.forEach((e, index2) => {
          if (index != index2) {
            this.vet_pedidos[index2].check = 5;
          }
        });
        this.show_table = false;
        setTimeout(() => {
          this.show_table = true;
        }, 20);
      } else if (this.vet_soma.length == 0) {
        
        let vet_clone = [...this.vet_pedidos];
        vet_clone.forEach((e, index2) => {
          this.vet_pedidos[index2].check = null;
        });

        this.show_table = false;
        setTimeout(() => {
          this.show_table = true;
        }, 20);
      }
      
      console.log(valor);
      console.log(soma);
      console.log("mostrando Pedido Selecioando");
      console.log(objeto_pedido);
    },
  },
};
</script>

<style>
</style>